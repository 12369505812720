@font-face {
  font-family: 'Founders Grotesk';
  src: url('founders-grotesk-web-light.woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Founders Grotesk Italic';
  src: url('founders-grotesk-web-light-italic.woff2');
  font-weight: normal;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'BeniBold';
  src: url('beni-bold.eot');
  src: url('beni-bold.eot?#iefix') format('embedded-opentype'),
    url('beni-bold.woff2') format('woff2'), url('beni-bold.woff') format('woff'),
    url('beni-bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'BeniRegular';
  src: url('beni-regular.woff2') format('woff2'), url('beni-regular.woff') format('woff'),
    url('beni-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
